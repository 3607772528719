import Rollbar from 'rollbar';
import store from './vue-apps/stores/store';


window.STORE = store;


if(ROLLBAR_ENABLED) {
    Vue.prototype.$rollbar = new Rollbar({
        accessToken: ROLLBAR_ACCESS_TOKEN,
        captureUncaught: true,
        captureUnhandledRejections: true,
        payload: {
            environment: 'production',
            client: {
                sessionID: window.SESSION_ID,
            }
        },
        checkIgnore: function(isUncaught, args, payload) {
            var regex = /Baiduspider|Googlebot|Bingbot|Slurp|DuckDuckBot|YandexBot|Sogou|Exabot|facebot|ia_archiver|AdsBot-Google/;
            if (window.navigator.userAgent && regex.exec(window.navigator.userAgent)) {
                // ignore bots
                return true;
            }
            // no other ignores
            return false;
        }
    });

    Vue.config.errorHandler = (err, vm, info) => {
        vm.$rollbar.error(err);
        throw err; // rethrow
    };
}