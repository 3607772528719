import { readDataFromContext } from '../../utils/utils.js';
import Cookies from 'js-cookie';


const DEFAULT_CURRENCY_CODE = 'USD';
const PREFIX_REQUIRED = ['USD', 'AUD', 'CAD'];


export default {
    namespaced: true,
    state: {
        location: undefined,
        country: undefined,
        availableCurrencies: null,
        selectedCurrencyCode: undefined,
        modals: {},
        openModalId: undefined,
        priorityThreshold: -1,
    },
    getters: {
        location(state) {
            return readDataFromContext('#location-data');
        },
        availableCurrencies(state) {
            if(!state.availableCurrencies) {
                state.availableCurrencies = readDataFromContext('#available-currencies');
            }
            return state.availableCurrencies;
        },
        selectedCurrency(state, getters) {
            let currency = getters.availableCurrencies[DEFAULT_CURRENCY_CODE];

            if(state.selectedCurrencyCode) {
                currency = getters.availableCurrencies[state.selectedCurrencyCode];
            } else if(
                getters.location &&
                getters.location.currency in getters.availableCurrencies
            ) {
                currency = getters.availableCurrencies[getters.location.currency];
            }

            if(
                PREFIX_REQUIRED.includes(currency.code) &&
                getters.location &&
                getters.location.country_code &&
                getters.location.country_code != currency.country_code &&
                !currency.symbol.includes(currency.country_code)
            ) {
                currency.symbol = `${currency.country_code}${currency.symbol}`;
            }
            return currency;
        },
        getCurrency: (state, getters) => (currencyCode) => {
            return getters.availableCurrencies[currencyCode];
        },
        // it's not good to have it here, check if possible to move it somewhere
        convertUSDToCurrency: (state, getters) => (price, precision = 2) => {
            let priceConverted = Number(price);
            if(getters.selectedCurrency.code != DEFAULT_CURRENCY_CODE) {
                priceConverted = priceConverted * Number(
                    getters.selectedCurrency.exchange_rate
                );
            }
            return priceConverted.toFixed(precision);
        },
        convertCurrencyToUSD: (state, getters) => (price, precision = 2) => {
            let priceConverted = Number(price);
            if(getters.selectedCurrency.code != DEFAULT_CURRENCY_CODE) {
                priceConverted = priceConverted / Number(
                    getters.selectedCurrency.exchange_rate
                );
            }
            return priceConverted.toFixed(precision);
        },
        modalsById(state) {
            return state.modals;
        },
        openRequestedModals(state) {
            return Object.values(state.modals)
                .filter(modal => modal.openRequested)
                .sort((a, b) => b.priority - a.priority);
        },
        currentOpenModal(state, getters) {
            return getters.modalsById[state.openModalId];
        },
    },
    actions: {
        initCurrency({commit, getters, state}, currency){

            if(!currency) {
                currency = Cookies.get('selectedCurrency');
            }
            if(currency) {
                currency = currency.toUpperCase();
            }
            if(!currency || !getters.availableCurrencies[currency]){
                currency = getters.location.currency
            }
            commit('setCurrency', currency);
        },

        registerModal({ commit }, { modalId, name, priority = 0 }) {
            let modal = { id: modalId, name, priority, openRequested: false };
            commit('addModal', modal);
        },
        removeModal({ commit }, modalId) {
            commit('removeModal', modalId);
        },
        requestModalOpen({ commit, dispatch }, modalId) {
            commit('updateModalState', { modalId, openRequested: true });
            dispatch('openNextModal');
        },
        closeModal({ commit, dispatch }, modalId) {
            commit('updateModalState', { modalId, openRequested: false });
            dispatch('openNextModal');
        },
        openNextModal({ commit, getters }) {
            if (getters.openRequestedModals.length > 0) {
                const nextModal = getters.openRequestedModals[0];
                window.CURRENT_MODAL = nextModal.name;
                commit('setNextOpenModal', { modalId: nextModal.id, priorityThreshold: nextModal.priority });
            } else {
                window.CURRENT_MODAL = undefined;
                commit('setNextOpenModal', { modalId: undefined, priorityThreshold: -1 });
            }
        },
        switchCurrency({ commit }, currency) {
            return new Promise((resolve, reject) => {
                commit('setCurrency', currency);
                try {
                    resolve();
                } catch (error) {
                    reject(error);
                }
            });
        }
    },
    mutations: {
        setLocation(state, location) {
            state.location = location;
        },
        setCurrency(state, currency) {
            state.selectedCurrencyCode = currency;
            Cookies.set('selectedCurrency', currency, { expires: 365 }); // expires in 1 year
        },
        addModal(state, modal) {
            state.modals = { ...state.modals, [modal.id]: modal };
        },
        removeModal(state, modalId) {
            const { [modalId]: removed, ...rest } = state.modals;
            state.modals = rest;
        },
        updateModalState(state, { modalId, openRequested }) {
            if (state.modals[modalId]) {
                state.modals[modalId] = { ...state.modals[modalId], openRequested };
            }
        },
        setNextOpenModal(state, { modalId, priorityThreshold }) {
            state.openModalId = modalId;
            state.priorityThreshold = priorityThreshold;
        }
    }
}
