// import Vue from "vue";
// import Vuex from "vuex";
// import pick from "lodash";

// import intersectionWith from 'lodash/intersectionWith';

// import { submitLogEvent } from '../../utils/tracking';
// import { geometricDistance, cheapestRate } from '../../utils/utils';


// const stringify = require('json-stringify-deterministic')
// const CURRENCIES = ['USD', 'CAD', 'EUR', 'GBP', 'AUD'];

// const okSearchType = ['airport', 'city', 'continent', 'country', 'high_level_region', 'metro_station', 
//                     'multi_city_vicinity', 'nearme', 'neighborhood', 'point_of_interest', 'province_state', 'train_station'];




// const sortAscByPrice = function(a,b){
//   let result;
//   if (a.cheapestRate.USD===null) {
//     result = +1
//   }else if(b.cheapestRate.USD===null){
//     result = -1
//   }else{
//     result = parseFloat(a.cheapestRate.USD) - parseFloat(b.cheapestRate.USD)
//   }
  
//   return result;
// }

// const sortDescByPrice = function(a,b){
//   let result;
//   if (a.cheapestRate.USD===null) {
//     result = +1
//   }else if(b.cheapestRate.USD===null){
//     result = -1
//   }else{
//     result = parseInt(b.cheapestRate.USD) - parseInt(a.cheapestRate.USD)
//   }
  
//   // console.log(`${result}|${a.name}|${b.name}|${a.cheapestRate.USD}|${b.cheapestRate.USD}`);
//   return result;
// }

// const sortDescByStars = function(a,b){
//   return -1 * (parseFloat(a.stars) - parseFloat(b.stars));
// }


// const sortDescByRanking = function(a,b){
//   return -1 * (parseInt(a.ranking) - parseInt(b.ranking));
// }


// const sortAscByDistance = function(a,b){
//   return parseFloat(a.distance) - parseFloat(b.distance);
// }


// const dictSort = {
//   'price': sortAscByPrice,
//   '-price': sortDescByPrice,
//   '-stars': sortDescByStars,
//   '-ranking': sortDescByRanking,
//   'distance': sortAscByDistance
// }


// const resetOptionToCheckedFalse = function (arrayOfOptions) {
//   for (let index = 0; index < arrayOfOptions.length; index++) {
//     arrayOfOptions[index].checked = false;
//   }

// }

// const sortedByDistanceToPoi = function (hotelList, poiCenter){
//   let hotelListCopy = JSON.parse(JSON.stringify(hotelList));

// for (const h of hotelListCopy) {
//   h.distanceToPoi = geometricDistance(h.longitude, h.latitude, poiCenter.lng, poiCenter.lat);
// }

// return hotelListCopy.sort( (a,b) => a.distanceToPoi - b.distanceToPoi);
// }


// const deduplicateHotelsByCode = function (principal, secondary){

//   const codesPrincipal = principal.map(x => x.code);
//   const codesSecondary = secondary.map(x => x.code);
//   const newCodes = codesSecondary.filter(x => !codesPrincipal.includes(x));
//   const arrNewHotelsToadd = secondary.filter(x => newCodes.includes(x.code))

//   return arrNewHotelsToadd
// }

// const accommodationTypeToVuexOptionFormat = function (accommTypeFromBackend) {

//   let accommTypeArrayVuex = []
//   for (let index = 0; index < accommTypeFromBackend.length; index++) {
//     const element = accommTypeFromBackend[index];
//     //console.log(element);
//     accommTypeArrayVuex.push({ count: element.count, name: element.accommodation_type_code, checked: false, code: element.accommodation_type_code })
//   }
//   return accommTypeArrayVuex;
// }


// function rankings(arr) {
//   const sorted = [...arr].sort((a, b) => a - b);
//   return arr.map((x) => sorted.indexOf(x) );
// };

export default {
  namespaced:true,
  strict: true,
  state: {
    timerValue: 0,
    timerStarted: false,
    timerOn: false,
    view_type: undefined,
    abortController: new AbortController(),
    search: {

      search_id: "",
      hotels: [],
      pdpParams: {},
      page: 1,
      zoom: 11,
      bbox: null,
      searchStarted: false,
      loadingMore: false,
      selectedRegion: {},
      backupedSelectedRegion: {},
    },
    ui: {
      currency: "USD",
      selectedHotel: undefined,
      is_member: false,
      showSelectedHotel: false
    },
    userChoices: {
      id_choice: 0,
      region_id: 0,
      lng: 0,
      lat: 0,
      sort: null,
      hotels_highlighted: [],
      minPrice: undefined,
      maxPrice: undefined,

      check_in: moment().add(1, 'day').format('YYYY-MM-DD'),
      check_out: moment().add(2, 'day').format('YYYY-MM-DD'),
      paxes: [{
        adults:2,
        children:[]
      }],
      accommodation_type: [],
      budget: [],
      segments: [],
      amenities: [],
      stars: [],
      showPriceless: true,
    },
    selectedHotelOnMap: null
  },
  getters: {

    // userChoicesSort(state){
    //   return state.userChoices.sort
    // },
    // firstHighLightedObject(state){
    //   const highLighted =  parseInt(state.userChoices.hotels_highlighted[0]);
    //   // console.log({highLighted});
    //   // console.log({'state.search.hotels.length':state.search.hotels.length});
    //   return state.search.hotels.filter(x=>x.code===highLighted)[0]

    // },


    // timer(state){
    //   return state.timerValue;
    // },
    // regionName(state){
    //   return state.search.selectedRegion.name
    // },

    // selectedPlusNearest(state, getters){
      
    //   const selected = state.selectedHotelOnMap;
    //   if(selected){
    //     let selectedPlusNearestArray = [state.search.hotels.find(x=>x.code===selected)]
    //     const nearestSelectedCodes = getters.nearestArrays[selected].slice(0,3);
    //     const nearestSelectedObjects = intersectionWith(state.search.hotels, nearestSelectedCodes, (o, n) => o.code === n);
    //     selectedPlusNearestArray.push(...nearestSelectedObjects)
    //     return  selectedPlusNearestArray
    //   }

    //   return []
    // },

    // nearestArrays(state, getters){
    //   let nearestsHotels = {}

    //   if (state.userChoices.showPriceless) {
    //       let qHotels = state.search.hotels.length;

    //       for (let i = 0; i < qHotels; i++) {
    //         let cols = new Array()
    //         const hotel_i = state.search.hotels[i];
    //         for (let j = 0; j < qHotels; j++) {
    //           const hotel_j = state.search.hotels[j];
    //           if (i === j) {
    //             cols[j] = Infinity //obviously is not, just to always have the highest rankin 
    //           } else {

    //             let distance = geometricDistance(hotel_i.longitude, hotel_i.latitude, hotel_j.longitude, hotel_j.latitude)
    //             cols[j] = distance
    //           }
    //         }
    //         let rankingArray = rankings(cols)
    //         let nearestHotelArray = new Array(rankingArray.length)
    //         for (let k = 0; k < rankingArray.length; k++) {
    //           nearestHotelArray[rankingArray[k]] = state.search.hotels[k].code
    //         }

    //         nearestsHotels[hotel_i.code] = nearestHotelArray.slice(0, -1); /* -1 is itself, with highest ranking */
    //       }
    //       return nearestsHotels;
    //   }
    //   else //no prices excluded
    //     {
    //       let qHotels = getters.hotelObjectsFilteredByMinMaxPrice.length;
    //       for (let i = 0; i < qHotels; i++) {
    //         let cols = new Array()
    //         const hotel_i = getters.hotelObjectsFilteredByMinMaxPrice[i];
    //         for (let j = 0; j < qHotels; j++) {
    //           const hotel_j = getters.hotelObjectsFilteredByMinMaxPrice[j];
    //           if (i === j) {
    //             cols[j] = Infinity //obviously is not, just to always have the highest rankin 
    //           } else {
    //             let distance = geometricDistance(hotel_i.longitude, hotel_i.latitude, hotel_j.longitude, hotel_j.latitude)
    //             cols[j] = distance
    //           }
    //         }
    //         let rankingArray = rankings(cols)
    //         let nearestHotelArray = new Array(rankingArray.length)
    //         for (let k = 0; k < rankingArray.length; k++) {
    //           // nearestHotelArray[rankingArray[k]] = getters.hotelObjectsFilteredByMinMaxPrice[k].code
    //           nearestHotelArray[rankingArray[k]] = getters.hotelObjectsFilteredByMinMaxPrice[k].code
    //         }
    //         nearestsHotels[hotel_i.code] = nearestHotelArray.slice(0, -1); /* -1 is itself, with highest ranking */
    //       }
    //       return nearestsHotels;
    //     }

    // },
    
    // hotelCodesFilteredByMinMaxPrice(state, getters){
    //   // .map(x => Object.values(x)).flat().filter(x => x>= state.userChoices.minPrice  && x<= state.userChoices.maxPrice )
    //   return getters.getArrayOfPricesByCurrency[state.ui.currency].filter(
    //     x=> Object.values(x)[0]>= state.userChoices.minPrice && Object.values(x)[0]<= state.userChoices.maxPrice ).map(x=>parseInt(Object.keys(x)[0]))
      
    // },



    // hotelCodesWithNoPrice(state, getters){
    //   return getters.getArrayOfPricesByCurrency[state.ui.currency].filter(x => Object.values(x)[0]===null ).map(x=>parseInt(Object.keys(x)[0]))
      
    // },

    // hotelsFilteredByMinMaxSelection(state, getters){
    //   return state.search.hotels.filter(x => x.cheapestRate[state.ui.currency] >= state.userChoices.minPrice 
    //                                && x.cheapestRate[state.ui.currency]<= state.userChoices.maxPrice);

    // },

    // hotelObjectsFilteredByMinMaxPrice(state, getters){
    //   const hotels = state.search.hotels;
    //   if (state.userChoices.sort) {
    //     // console.log("sort: ", state.userChoices.sort);
    //     return hotels.sort(dictSort[state.userChoices.sort])
    //   }else{
    //     console.log("no user choice sort");
    //     return hotels
    //   }
      
      
    // },    
    // hotelObjectsWithNoPrice(state, getters){
    //   return state.search.hotels.filter(x => getters.hotelCodesWithNoPrice.includes(x.code))
    // },
    // getArrayOfPricesByCurrency(state, getters) {
    //   let dictPricesByCurrency = {}

    //   const hotelWithRooms = state.search.hotels //.filter(x => x.rooms[0] !== undefined);
    //   // initialize 
    //   for (const curr of CURRENCIES) {
    //     dictPricesByCurrency[curr] = []
    //   }

    //   for (const hotel of hotelWithRooms) {
    //     for (const curr of CURRENCIES) {
    //       dictPricesByCurrency[curr].push( {[hotel.code] : cheapestRate(hotel, curr)/getters.qNights }  );
    //     }

    //   }

    //   return dictPricesByCurrency;
    // },



    // getMinMaxByCurrency(_, getters) {
    //   let dictMinMaxPricesByCurrency = {}
    //   // initialize 
    //   for (const curr of CURRENCIES) {
    //     dictMinMaxPricesByCurrency[curr] = []
    //   }
    //   for (const curr of CURRENCIES) {
    //     // console.log(curr);
    //     // console.log(`${getters.getArrayOfPricesByCurrency[curr].map(x => Object.values(x)).flat().filter(x=>x<Infinity)}`);
    //     dictMinMaxPricesByCurrency[curr].push(Math.floor(
    //       Math.min(...getters.getArrayOfPricesByCurrency[curr].map(x => Object.values(x)).flat().filter(x=>x>0)  )
    //       ),
    //       Math.ceil(Math.max(...getters.getArrayOfPricesByCurrency[curr].map(x => Object.values(x)).flat().filter(x=>x>0))
    //       )
    //     );
    //   }

    //   return dictMinMaxPricesByCurrency;
    // },
    // minMaxPrice(state, getters){
    //     return getters.getMinMaxByCurrency[state.ui.currency];

    // },



    // getSearchType(state){
    //   return state.search.selectedRegion.type;
    // },
    // qNights(state){
    //   const check_in = new Date(state.userChoices.check_in);
    //   const check_out = new Date(state.userChoices.check_out);
    //   const diffTime = Math.abs(check_out - check_in);
    //   const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
      
    //   return diffDays;
    // },
    // sortedByDistance(state) {

    //   let hotelsCopy = JSON.parse(JSON.stringify(state.search.hotels));
    //   for (const h of hotelsCopy) {   
    //     h.distanceToPoi = geometricDistance(h.longitude, h.latitude, state.userChoices.lng, state.userChoices.lat);
    //   }
    
    //   return hotelsCopy;

    // },

    // hotelCodes(state){

    //   return state.search.hotels.map(x=>x.code);
    // },

    // notEnoughHotels(state, getters){
    //   return state.search.hotels.length < 10;
    // },
    // selectedCurrency(state){
    //   return state.ui.currency;
    // },
    // hotelListCopy(state){
      
    //   return JSON.parse(JSON.stringify(state.search.hotels))
    // },

    
    // view_type(state){
    //   return state.view_type;
    // },

    
    // paxesToURLParams(state) {
    //   let params = {};
    //   let room_num = 0
    //   params['rooms'] = state.userChoices.paxes.length
    //   for (let pax of state.userChoices.paxes) {
    //     params[`room${room_num}_adults`] = pax.adults;
    //     //params[`room${room_num}_children`] = pax.children;
    //     if (pax.children) {
    //       params[`room${room_num}_children_ages`] = pax.children.join();
    //     }
    //     room_num = room_num + 1;
    //   }
    //   return params
    // },
    // lenHotels(state) {
    //   return state.search.hotels.length || 0;
    // },
    // hotelListBBox(state){

    //   const hotelList = state.search.hotels;

    //   if (hotelList.length>0) {
    //     const arrLat = hotelList.map(x => x.latitude)
    //     const arrLng = hotelList.map(x => x.longitude)


    //   return {
    //           north: Math.max(...arrLat), south: Math.min(...arrLat),
    //           east: Math.max(...arrLng), west: Math.min(...arrLng),
    //         }
    //   }
    // },

    // hashUserChoices(_, getters) {
    //   return stringify(getters.queryParams);
    // },
    // queryParams(state, getters) {
      
    //   let params = {}
    //   if(state.view_type){
    //     params['view_type'] = state.view_type;
    //   }
    //   for (const [choice, value] of Object.entries(state.userChoices)) {
        
    //     if(value){
    //       if (['accommodation_type', 'budget', 'segments', 'amenities', 'stars'].includes(choice)) {
    //         if (getters.checkedOptionsGetter(choice).length) {
    //           params[choice] = getters.checkedOptionsGetter(choice, 'code').join()
    //         }
    //       }
    //       else {
    //         if (choice == 'paxes') {
    //           params = {...params, ...getters.paxesToURLParams}
              
    //         } else if (['check_in', 'check_out', 'region_id', 'sort'].includes(choice)) {
    //           if(value){
    //             params[choice] = value;//state.userChoices[choice];
    //           }
    //         }
    //         else if(choice==='hotels_highlighted' && value[0]){
    //           params['hotel_highlight'] = value[0] //first item of hotels_highlighted
    //         }
    //       } 
    //     }        
    //   }

    //   try {
    //     const bboxObj = JSON.parse(state.search.bbox)
    //     params = {...params, ...bboxObj}
    //   } catch (error) {
    //   }

    //   if(state.userChoices.lng && state.userChoices.lat){
    //     params = {...params, lng: state.userChoices.lng, lat: state.userChoices.lat}
    //   }

    //   return params
    // },
    // queryParamsToUrlFormat(_, getters) {
    //   let base_url = '';
    //   base_url += Object.entries(getters.queryParams)
    //     .map(   ([k, v])   => {
    //       if(v){
    //         return `${encodeURIComponent(k)}=${encodeURIComponent(v)}`;
    //       }
    //       return "";
    //     }).join("&");
      
    //   return base_url;
    // },
    // resultsAreStale(state, getters) {

    //   return state.search.search_id != getters.hashUserChoices
    // },
    // userChoicesKeys(state) {
    //   return Object.keys(state.userChoices);
    // },
    userChoices(state) {
      return state.userChoices;
    },
    // availableOptionsGetter(state) {
    //   return function (option) {
    //     return state.userChoices[option]
    //   };
    // },
    // checkedOptionsGetter(state) {
    //   return function (option, key = 'code') {
    //     if (state.userChoices[option].filter) {
    //       return state.userChoices[option].filter(elem => elem.checked === true).map(e => e[key]);
    //     }
    //     return []
    //   };
    // },


  },
  actions: {
    // startTimer({ commit , state}) {
    //   console.log("TIMER STARTED");
    //   state.timerStarted = true;
    //   state.timerOn =true;
    //   let timer = setInterval(() => {
    //     // console.log(`state.timerValue: ${state.timerValue}`);
    //     if(state.timerOn === true){
    //       commit('setTimerValue', state.timerValue + 1);
    //     }
    //     if(state.timerStarted === false){
    //       clearInterval(timer)
    //     }
    //     // else{
    //     //   clearInterval(timer)
    //     // }
    //   }, 1000); // Update the timer every second
     
    // },
    // stopTimer({ commit }) {
    //   console.log("TIMER STOPPED");
    //   commit('stopTimer')
    // },
    // resumeTimer({ commit }){
    //   commit('resumeTimer');
      
    // },

    // incrementalSearch({ commit, state, getters }, bounds){

    //   let params = { ...getters.queryParams };
    //   let merged = { ...params, ...bounds }
    //   delete merged.region_id //this is necessary because backend endpoint works different if region_id is present

    //   commit('startSearchRequest', { search_id: getters.hashUserChoices, queryParams: params });
    //   return axios.get('/apis/hotels/search/', { params: merged, signal: state.abortController.signal })
    //     .then((response) => {
    //       let hotels = response.data;
          
    //       if(hotels.length){
    //         commit('updateHotels', { hotels: sortedByDistanceToPoi(hotels, {lng: state.userChoices.lng, lat: state.userChoices.lat} ) });
    //         commit('endSearchRequest');
    //       }else{
    //         console.log("NO HOTELS")
    //       }
    //     })
    //     .catch((e) => {
    //       console.log(e);
    //     });
    // },
    // fetchHotelsByLngLat({ commit, state, getters }, bounds) {
    //   console.log("fetchHotelsByLngLat");
    //   console.log({bounds});

    //   let params = { ...getters.queryParams };
    //   let merged = { ...params, ...bounds };
    //   delete merged.region_id //this is necessary because backend endpoint works different if region_id is present

    //   console.log({merged})
    //   commit('startSearchRequest', { search_id: getters.hashUserChoices, queryParams: params });

    //   return axios.get('/apis/hotels/search/', { params: merged})   // , signal: state.abortController.signal 
    //     .then((response) => {
          
    //       if( !okSearchType.includes(response.data['region-data'].type) ){
    //         console.log("region-data ", response.data['region-data'].type)
    //         submitLogEvent('srp_error_distanceMessage_CardHotel', {})
    //       }/* else{
    //         console.log("OK SEARCH TYPE");
    //       } */

    //       let hotels = response.data;
    //       console.log(hotels)
    //       if(hotels.length){
    //         commit('addHotels', { search_id: getters.hashUserChoices, hotels: hotels });
    //         commit('endSearchRequest');
    //         submitLogEvent('srp_hotels_loaded_by_lng_lat', {num_hotels:state.search.hotels.length});
    //       }else{
    //         console.log("NO HOTELS")
    //       }
    //       // let nextPage = response.data.next;


    //     })
    //     .catch((e) => {
    //       // commit('endSearchRequest');
    //       console.log(e);
    //       reject();
    //     });
    // },
  },
  mutations: {
    // stopTimer(state){
    //   // console.log("stopTimer");
    //   state.timerStarted = false;
    // },
    // pauseTimer(state){
    //   // console.log("pauseTimer");
    //   state.timerOn = false;
    // },
    // unPauseTimer(state){
    //   // console.log("unPauseTimer");
    //   state.timerOn = true;
    // },
    // setTimerValue(state, value) {
    //   state.timerValue = value;
    // },
    
    // changeShowPriceless(state, payload){
    //   state.userChoices.showPriceless = payload;
    // },
    // setSelectedHotelOnMap(state, payload){
    //   state.selectedHotelOnMap = payload;
    // },
    // setMinPrice(state, payload){
    //   state.userChoices.minPrice = payload;
    // },
    // setMaxPrice(state, payload){
    //   state.userChoices.maxPrice = payload;
    // },
    // setSearchPage(state, page){
    //   state.search.page = page;
    // },
    // setSearchType(state, payload){
    //   state.search.selectedRegion.type = payload;
    // },
    // setMapZoom(state, payload){
    //   state.search.zoom = payload;
    // },
    // changeViewType(state, view_type){
    //   //console.log(`ìnside changeViewType ${view_type}`)
    //   state.view_type = view_type;
    // },
    // pushElementHotelHighlight(state, code){
    //   state.userChoices.hotels_highlighted.push(code)
    // },
    // backupSelectedRegion(state){
    //   //console.log("in backupSelectedRegion")
    //   //console.log(state.search.selectedRegion)
    //   state.search.backupedSelectedRegion = {...state.search.selectedRegion};
    // },
    // increaseIdChoice(state){
    //   state.userChoices.id_choice++;
    // },

    // resetAllFiltersOptions(state) {

    //   const userChoices = ["accommodation_type", "budget", "segments", "amenities", "stars"]
    //   for (const uc of userChoices) {
    //     resetOptionToCheckedFalse(state.userChoices[uc]);
    //   }
    // },
    // resetDestination(state) {
    //   if(state.search.backupedSelectedRegion){
    //     //console.log('COPY BEFORE SELECTION')
    //     state.search.selectedRegion = {...state.search.backupedSelectedRegion};
    //     state.search.backupedSelectedRegion = {};
    //   }else{
    //     state.search.selectedRegion = {};
    //   }

      
    // },
    // resetPaxes(state) {
    //   state.userChoices.paxes = [];
    // },
    // cancelSearch(state) {
    //   state.abortController.abort();
    //   state.abortController = new AbortController();
    //   state.search.loadingMore = false;
    //   //console.log("cancelling past requests")
    // },
    updateUserChoiceValue(state, { choice, value }) {

      state.userChoices[choice] = value;
    },
    // updateSearchValue(state, { choice, value }) {

    //   state.search[choice] = value;
    // },
    // setMemberStatus(state, is_member) {
    //   state.ui.is_member = is_member;
    // },
    // setCurrency(state, currency) {
    //   state.ui.currency = currency;
    // },

    // setLatLng(state, {lat, lng}){
    //   state.userChoices.lat = lat;
    //   state.userChoices.lng = lng;
    // },
    selectRegion(state, selectedRegion) {
      state.search.selectedRegion = selectedRegion;
      state.userChoices.region_id = selectedRegion.id;
      state.userChoices.lat = selectedRegion.center_latitude;
      state.userChoices.lng = selectedRegion.center_longitude;
    },
    // selectRegionID(state, region_id) {
    //   state.userChoices.region_id = region_id;
    // },
    // selectHotel(state, selectedHotel) {
    //   state.ui.selectedHotel = selectedHotel;
    //   state.ui.showSelectedHotel = true;
    // },
    // deselectHotel(state) {
    //   state.ui.showSelectedHotel = false;
    // },
    // startSearchRequestWithoutEliminateExistingHotels(state) {
    //   console.log("startSearchRequest2");
    //   state.search.loadingMore = true;
    //   // console.log(state.search.loadingMore);
      
    // },
    // startSearchRequest(state, payload) {
    //   state.search.loadingMore = true;
    //   state.search.searchStarted = true;
    //   if (state.search.search_id != payload.search_id) {
    //     state.search.hotels = [];
    //     state.search.pdpParams = { ...payload.queryParams };
    //     state.search.search_id = payload.search_id;
    //     state.search.page = 1
    //   }
    //   state.abortController = new AbortController();
    //   //this.$emit('search-started', payload.queryParams);
    //   // Update the URL if the results are stale
    //   /*if (state.search.hotels.length == 0) {
    //     const searchURL = new URL(window.location);
    //     searchURL.search = new URLSearchParams(payload.queryParams);
    //     console.log(payload)
    //     window.history.replaceState({}, '', searchURL);
    //   }*/
    // },
    // endSearchRequest(state) {
    //   state.search.loadingMore = false;
    // },
    // addHotels(state, searchResults) {
    //   // state.search.hotels.push(...searchResults.hotels);
    //   state.search.hotels = searchResults.hotels;
    //   state.search.page = searchResults.nextPage;
    // },
    // updateHotels(state, payload) {
      
      
    //   const hotelsToBeAddedWithoutDuplicates = deduplicateHotelsByCode(state.search.hotels, payload.hotels)
    //   //console.log("ON updateHotels")
    //   //console.log(`RECEIVED ${payload.hotels.length} BUT ONLY ${hotelsToBeAddedWithoutDuplicates.length} TO BE UPDATED`)
      
    //   // state.search.hotels.push(...searchResults.hotels);
    //   state.search.hotels.push(...hotelsToBeAddedWithoutDuplicates);
    
    // },
    // onChangeUpdateOption(state, { filterName, filterKey, value }) {
    //   // console.log(`onChangeUpdateOption: ${filterName} - ${filterKey} - ${value}`)
    //   if (state.userChoices[filterName]) {
    //     const index = state.userChoices[filterName].findIndex(e => e[filterKey] === value);

    //     state.userChoices[filterName][index].checked = !state.userChoices[filterName][index].checked;
    //     //don't be confused here: the key is the value that passed the vue object, and the value is the .checked attribute
    //     submitLogEvent(`option_${filterName}_clicked`, { key: value, value: state.userChoices[filterName][index].checked});
    //     state.search.page = 1;
    //   }

    // },


  }
};
