import axios from 'axios';
import axiosRetry from 'axios-retry';

const API = axios.create({
  baseURL: API_URL,
  validateStatus: function (status) {
    return (status >= 200 && status < 300);
  },
});


API.defaults.xsrfHeaderName = "X-CSRFTOKEN";
API.defaults.xsrfCookieName = "csrftoken";
API.defaults.headers.common['X-Session'] = window.SESSION_ID || '';

API.interceptors.response.use(function (response) {
  if(response.headers['x-session']) {
    API.defaults.headers.common['X-Session'] = response.headers['x-session'];
  }
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
});

axiosRetry(API, {
  retries: 3,
  retryDelay: (retryCount) => {
    return 2000
  },
  retryCondition: (error) => {
    if(
      !error.response ||
      error.response.status === 501 || error.response.status === 502 ||
      error.response.status === 503 || error.response.status === 504
    ) {
      return true;
    }
    return false;
  },
});

export { API };
