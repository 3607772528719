import { API } from './_common'

export default {
  create(params, signal) {
    return API.post('/bookings/', params, {signal});
  },
  list() {
    return API.get('/apis/bookings/');
  },
  remove(slug) {
    return API.delete(`/apis/bookings/${slug}/remove/`);
  }
}
