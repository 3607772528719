import { geometricDistance } from '../../utils/utils'

export default {
    namespaced:true,
    state: {
        active: null,
        zoom: null,
        bbox: null,
        center: null,
        markersCount: 0,
        visibleMarkersCount: 0,

    },
    getters:{
        get_bounds(state){
            if(state.bbox){
                return { ne0: state.bbox._ne.lng, ne1: state.bbox._ne.lat, sw0: state.bbox._sw.lng, sw1: state.bbox._sw.lat, }
            }
            else{
                return undefined
            }
             
        },
        getCenter(state){
            if(state.center){
                return state.center;
            }
            else{
                return undefined
            }
        },
        getWidthMap(state) {
            // repeat state.bbox._ne.lat at las arguments because the WIDTH is needed, NOT THE DIAGONAL OF THE EXTREMES
            return state.center ? geometricDistance(state.bbox._ne.lng, state.bbox._ne.lat, state.bbox._sw.lng, state.bbox._ne.lat) : undefined;
        },
        getHeightMap(state) {
            // repeat state.bbox._ne.lng as third  because the HEIGHT is needed, NOT THE DIAGONAL OF THE EXTREMES
            return state.center ? geometricDistance(state.bbox._ne.lng, state.bbox._ne.lat, state.bbox._ne.lng, state.bbox._sw.lat) : undefined;
        },
        getExtremeDistanceMap(state) {
            return state.center ? geometricDistance(state.bbox._ne.lng, state.bbox._ne.lat, state.bbox._sw.lng, state.bbox._sw.lat) : undefined;
        },
        
    },
    // actions:{

    // },
    mutations:{
        setMapState(state, payload){
            state.active = payload;
        },
        setCenter(state, payload){
            state.center = payload;
        },
        setBbox(state, payload){
            state.bbox = payload;
        },
        setZoom(state, payload){
            state.zoom = payload;
        },
        setMapObject(state, payload){
            state.mapObject = payload;
        },
        setMarkers(state, {total, visibles}){
            //console.log({total, visibles})
            state.markersCount = total;
            state.visibleMarkersCount = visibles;
        },
    }
}