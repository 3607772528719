import { API } from './_common'

export default {
  search(params, signal) {
    return API.get('/apis/hotels/search/', {params, signal});
  },
  detail(hotelId, params, signal) {
    return API.get(`/apis/hotels/${hotelId}/`, {params, signal});
  },
  prices(hotelId, params, signal) {
    return API.get(`/apis/hotels/${hotelId}/prices/`, {params, signal});
  },
  nearby(hotelId, params, signal) {
    return API.get(`/apis/hotels/nearby/${hotelId}/`, {params, signal});
  }
}
