import { v4 as uuidv4 } from 'uuid';
import { submitLogEvent } from './tracking';


var GeoPoint = require('geopoint');

export const MOBILE_WIDTH = 767;
export const TABLET_WIDTH = 992;

export function getDisplayWidth() {
    let width = window.innerWidth
    || document.documentElement.clientWidth
    || document.body.clientWidth;
    return width;
}

export function getQueryStringValue (key) {
  let searchParam = new URL(window.location).searchParams.get(key);
  let pathnameParam = decodeURIComponent(window.location.pathname.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
  return searchParam || pathnameParam;
}

export function nextDay(x){
    var now = new Date();    
    now.setDate(now.getDate() + (x+(7-now.getDay())) % 7);
    return now;
}



export function hashCode(string){
    var hash = 0;
    for (var i = 0; i < string.length; i++) {
        var code = string.charCodeAt(i);
        hash = ((hash<<5)-hash)+code;
        hash = hash & hash; 
    }
    return hash;
}


function deg2rad(deg) {
    return deg * (Math.PI / 180)
}

export function geometricDistance(lon1, lat1,  lon2, lat2, measure='mi') {
    if(measure==='mi'){
        var R = 3959; // Radius of the earth in miles
    }else{
        var R = 6371; // Radius of the earth in km
    }
    // var R = 3959; 
    var dLat = deg2rad(lat2 - lat1);  // deg2rad below
    var dLon = deg2rad(lon2 - lon1);
    var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2)
        ;
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance 
    return d;
}
export function renameKeys(obj, newKeys) {
    //https://stackoverflow.com/questions/4647817/javascript-object-rename-key
    
    const keyValues = Object.keys(obj).map(key => {
      const newKey = newKeys[key] || key;
      return { [newKey]: obj[key] };
    });
    return Object.assign({}, ...keyValues);
}


export  function combinateBboxIncludeBoth(bbox1, bbox2){
    //given two bbox, gives the resulting bbox thant includes both
    let bboxIncudeBoth = {};
    bboxIncudeBoth['ne0'] = Math.min(bbox1['ne0'], bbox2['ne0']); 
    bboxIncudeBoth['ne1'] = Math.max(bbox1['ne1'], bbox2['ne1']); 
    bboxIncudeBoth['sw0'] = Math.max(bbox1['sw0'], bbox2['sw0']); 
    bboxIncudeBoth['sw1'] = Math.min(bbox1['sw1'], bbox2['sw1']); 

    return bboxIncudeBoth;
}

export function isObjectEmpty(objectName){
    // https://www.freecodecamp.org/news/check-if-an-object-is-empty-in-javascript/
    return (
      objectName &&
      Object.keys(objectName).length === 0 &&
      objectName.constructor === Object
    );
  };

  export function cheapestExternalRate(val, currency) {
    try {
        const extRatesObject = val.externalLinks[Object.keys(val.externalLinks)[0]].displayCurrency[currency];
        if (JSON.stringify(extRatesObject) === '{}') {
            return null;
        } else {
            const providersArray = Object.keys(val.externalLinks); //get all providers
            const arrayOfExternalLinksBaseRatesByCurrency = []
            for (const prov of providersArray) { //for each provider, the baseRate of the given currency
              arrayOfExternalLinksBaseRatesByCurrency.push(parseFloat(val.externalLinks[prov].displayCurrency[currency].baseRate))
            }
            return Math.min(...arrayOfExternalLinksBaseRatesByCurrency);
        }
    } catch (error) {
        return null;
    }
  }
  
  
  export function cheapestInternalRate(val, currency) {
    try {
        return val.rooms[0].rates[0].displayCurrency[currency].baseRate;
    } catch (error) {
        return null;
    }
  }
  
  export function cheapestRate(h, currency) {
    const arrayOfPrices = [cheapestExternalRate(h,currency),cheapestInternalRate(h,currency)].filter(x=>x>0);
    // console.log(`OK array of prices ${h.code}: ${cheapestExternalRate(h,currency)} / ${cheapestInternalRate(h,currency)} /  (${currency})`);
    if(arrayOfPrices.length) {
      return Math.min(  ...arrayOfPrices);
    }
    else {
      return null;
    }
    
  }

export function readDataFromContext(selector) {
    let $element = document.querySelector(selector);
    if($element && $element.textContent) {
        try {
            return JSON.parse($element.textContent);
        } catch (ex) {
            submitLogEvent('jsonParseFailed', {name:selector, msg:ex.message, data:$element.textContent});
            return default_value;
        }
    }
    return null;
}
