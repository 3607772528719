import { API } from './_common'

export default {
  createLogEvent(data) {
    return API.post('/logs/event/', data);
  },
  saveTrackData(data) {
    return API.post('/tracking/save-data/', data);
  }
}
