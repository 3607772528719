import Tracking from '../vue-apps/api/tracking';


export const EVENT_TYPE_LOAD = 1;
export const EVENT_TYPE_DATE_SET = 2;
export const EVENT_TYPE_LINK_CLICK = 3;


export function saveTrackData(eventType=EVENT_TYPE_LOAD, params=[]) {
  Tracking.saveTrackData({
    eventType: eventType,
    params: params,
    sentFrom: window.location.href,
  });
  // axios.post(
  //   API_URL + '/tracking/save-data/',
  //   {
  //     eventType: eventType,
  //     params: params,
  //     sentFrom: window.location.href,
  //   }
  // )
}

export function submitLogEvent(evname, data = {}){
    Tracking.createLogEvent({event: evname, data: data})
    .catch((error) => {
        console.log(error)
    });
    // axios.post('/logs/event/', {
    //     event: evname,
    //     data: data
    // }).catch(function (error) {
    //     console.log(error);
    // });
}

export function submitLogDataLayerGA(evname, pageType,  data = {}){
    window.dataLayer.push({
        'event': evname,
        'sessionID': window.SESSION_PUBLIC_KEY,
        'pageType': pageType,
        'payloadEvent': data
      });
}

export function submitLogDL_GA_plus_DB(evname, pageType,  data = {}){
    // console.log("submitLogDL_GA_plus_DB");
    // console.log({evname, pageType, data});
    if (window.CURRENT_MODAL) {
        const dataEnhanced = {...data, currentOpenModal: window.CURRENT_MODAL};
        submitLogEvent(evname, data);
        submitLogDataLayerGA(evname, pageType, data);
    } else {
        submitLogEvent(evname, data);
        submitLogDataLayerGA(evname, pageType, data);
    }
}

export function handleClickEvent(url, evname, data = {}, target){
    //submitLogEvent(evname, data);
    let timeout = 500;
    if(target){
        timeout = 0;
    }
    /* Delay needed to stop FF from aborting the log event request */
    setTimeout( function() {
        if(target){
            window.open(url, target);
        }else{
            window.location.href = url;
        } 
    }, timeout);
         
}